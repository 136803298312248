/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  List,
  ListItem,
  Box,
} from '@mui/material';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  deleteRecurringEventById,
  getRecurringEventsList,
} from '../../services/content.service';
import {
  formatTimestamp,
  getCurrentTimestamp,
} from '../../utils/datetime.util';
import ConfirmPopup from '../common/confirm-popup.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ManageEvents = ({ title, dataId, onCancel }) => {
  const [loading, setLoading] = useState(true);
  const [eventsData, setEventsData] = useState(null);
  const [showConfirmDailog, setShowConfirmDialog] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const selectedEventDateRef = useRef(null);

  const handleDeleteEvent = () => {
    deleteRecurringEventById(selectedEventDateRef.current.id)
      .then((res) => {
        if (res.data?.success) {
          const filteredEventsData = eventsData.filter(
            (item) => item.id !== selectedEventDateRef.current.id
          );
          setEventsData(filteredEventsData);
          setShowConfirmDialog(false);
          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'success',
            message: 'Events deleted successfully.',
          });
        }
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
      });
  };

  useEffect(() => {
    getRecurringEventsList(dataId)
      .then((res) => {
        setEventsData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      onClose={() => onCancel()}
      aria-labelledby="view-dialog-title"
      aria-describedby="view-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="view-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="view-dialog-description" sx={{ paddingY: 0 }}>
        {loading && <Loading />}

        {!loading && (
          <List>
            {eventsData.map((item, index) => (
              <ListItem
                key={index}
                disableGutters
                secondaryAction={
                  item.recurringTimestamp > getCurrentTimestamp() && (
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        setShowConfirmDialog(true);
                        selectedEventDateRef.current = item;
                      }}
                      size="small"
                      sx={{
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <Iconify icon="material-symbols:delete-outline" />
                    </IconButton>
                  )
                }
              >
                <Box>
                  {formatTimestamp(
                    item.recurringTimestamp,
                    'YYYY-MM-DD hh:mm A'
                  )}
                </Box>

                <Box sx={{ marginX: 2, fontSize: 'small' }}>
                  {item.recurringTimestamp <= getCurrentTimestamp() &&
                  item.recurringTimestamp + item.duration * 60 >=
                    getCurrentTimestamp() ? (
                    <Box
                      sx={{
                        borderRadius: '25px',
                        background: '#ffc7d1',
                        color: '#ff3939',
                        paddingX: 2,
                      }}
                    >
                      Live
                    </Box>
                  ) : item.recurringTimestamp < getCurrentTimestamp() ? (
                    <Box
                      sx={{
                        borderRadius: '25px',
                        background: '#d1d1d1',
                        color: '#413f3f',
                        paddingX: 2,
                      }}
                    >
                      Past
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        borderRadius: '25px',
                        background: '#b0f1b0',
                        color: '#006300',
                        paddingX: 2,
                      }}
                    >
                      Upcoming
                    </Box>
                  )}
                </Box>
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>

      {showConfirmDailog && (
        <ConfirmPopup
          title={`Delete ${formatTimestamp(
            selectedEventDateRef.current.recurringTimestamp,
            'YYYY-MM-DD hh:mm A'
          )} Event`}
          message={`Do you want to delete ${formatTimestamp(
            selectedEventDateRef.current.recurringTimestamp,
            'YYYY-MM-DD hh:mm A'
          )} event? This will also delete event registrations references (if any). You can not undo this action!`}
          onCancel={() => setShowConfirmDialog(false)}
          onSuccess={handleDeleteEvent}
        />
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

ManageEvents.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ManageEvents;
