export const MULTIPLE_CAT_PER_VIDEO = false;
export const MULTIPLE_GROUP_PER_VIDEO = false;
export const SHOW_OVERVIEW_DASHBOARD = true;
export const SHOW_REALTIME_DASHBOARD = false;
export const SHOW_SUBSCRIBERS = true;
export const SHOW_SUBSCRIPTIONS = true;
export const SHOW_GROUPS = true;
export const SHOW_PACKAGES = true;
export const SHOW_ADMIN_USERS = false;
export const SHOW_NOTIFICATIONS = true;
export const SHOW_SALUTATION = false;
export const SHOW_CONTENT_SECTION = true;
export const SHOW_CONTENT_CATEGORIES = true;
export const SHOW_CONTENT_VIDEOS = true;
export const SHOW_CONTENT_COMMUNITY_GATHERINGS = true;
export const SHOW_CONTENT_POPULAR = true;
export const SHOW_CONTENT_SEQUENCE = true;
export const SHOW_TESTIMONIALS = true;
export const SHOW_ARTICLE = true;
export const SHOW_VIDEO_COMMENTS = true;
export const SHOW_REPORTS_OVERVIEW_VIDEOS = true;
export const SHOW_REPORTS_LIVE_USERS = true;
export const SHOW_REPORTS_WATCH_SESSIONS = true;
export const SHOW_REPORTS_SUBSCRIPTIONS = true;
export const SHOW_REPORTS_LIVE_SESSIONS = true;

// Define commonly used constants
export const LS_ADMIN_USER_AUTH_TOKEN = '__ssft_admin_t';
export const LS_ADMIN_USER_NAME = '__ssft_admin_n';
export const LS_ADMIN_USER_EMAIL = '__ssft_admin_e';
export const LS_ADMIN_USER_ROLE = '__ssft_admin_r';
export const LS_ADMIN_USER_ID = '__ssft_admin_i';

export const TYPE_LABELS = {
  // 0: 'Text',
  1: 'Link',
  // 2: 'Audio',
};

export const TYPE_LABELS_KEYS = {
  // Text: 0,
  Link: 1,
  // Audio: 2,
};

export const STATUS_LABELS = {
  0: 'Inactive',
  1: 'Active',
  2: 'Deleted',
  5: 'Requested Delete',
  6: 'Pendig For Approval',
  7: 'Approved',
  11: 'Pending Verification',
  12: 'Cancellation Applied',
  13: 'Cancelled',
};

export const VIDEO_TYPES = {
  VIDEOS: {
    value: 'VIDEOS',
    label: 'Videos',
  },
  CHAPTER: {
    value: 'CHAPTER',
    label: 'Chapter',
  },
  TRANSMISSION: {
    value: 'TRANSMISSION',
    label: 'Transmission',
  },
  PRACTICE: {
    value: 'PRACTICE',
    label: 'Practice',
  },
  TAO_SONG: {
    value: 'TAO_SONG',
    label: 'Tao Calligraphy Meditation',
  },
  BLESSINGS: {
    value: 'BLESSINGS',
    label: 'Blessings',
  },
  LIVE_EVENT: {
    value: 'LIVE_EVENT',
    label: 'Live Event',
  },
  REPLAY_EVENT: {
    value: 'REPLAY_EVENT',
    label: 'Replay Event',
  },
  TESTIMONIALS: {
    value: 'TESTIMONIALS',
    label: 'Testimonials',
  },
  MEDITATION: {
    value: 'MEDITATION',
    label: 'Meditation',
  },
  TUTORIAL: {
    value: 'TUTORIAL',
    label: 'Tutorial',
  },
};

export const POPULAR_CONTENT_TYPES = {
  PROGRAM: {
    value: 'PROGRAM',
    label: 'Program',
  },
  COURSE: {
    value: 'COURSE',
    label: 'Course',
  },
  TAO_SONG: {
    value: 'TAO_SONG',
    label: 'Tao Calligraphy Meditation',
  },
  LIVE_EVENT: {
    value: 'LIVE_EVENT',
    label: 'Live Event',
  },
  BLESSINGS: {
    value: 'BLESSINGS',
    label: 'Blessings',
  },
  MEDITATION: {
    value: 'MEDITATION',
    label: 'Meditation',
  },
};

export const FREQUENCY_TYPE = {
  WEEKLY: {
    value: 'WEEKLY',
    label: 'Weekly',
  },
  MONTHLY: {
    value: 'MONTHLY',
    label: 'Monthly (Days)',
  },
  // MONTHLY_WEEK_DAY: {
  //   value: 'MONTHLY_WEEK_DAY',
  //   label: 'Monthly (Week Days)',
  // },
};

export const EVENT_TYPE = {
  LIVE: {
    value: 'LIVE',
    label: 'Live',
  },
  ZOOM: {
    value: 'ZOOM',
    label: 'Zoom',
  },
};

export const STATUS_COLORS = {
  0: 'error',
  1: 'success',
  2: 'error',
  5: 'warning',
  6: 'warning',
  7: 'success',
  11: 'warning',
  12: 'warning',
  13: 'warning',
};

export const LANGUAGES = [
  {
    value: 'hi',
    label: 'Hindi',
  },
  {
    value: 'fr',
    label: 'French',
  },
  {
    value: 'de',
    label: 'German',
  },
  {
    value: 'zh',
    label: 'Chinese',
  },
  {
    value: 'ja',
    label: 'Japanese',
  },
  {
    value: 'nl',
    label: 'Dutch',
  },
  {
    value: 'es',
    label: 'Spanish',
  },
  {
    value: 'gu',
    label: 'Gujarati',
  },
];

export const CONTENT_ALIAS = {
  SECTION_SINGULAR: 'Program/Course Group',
  SECTION_PLURAL: 'Program/Course Groups',
  CATEGORY_SINGULAR: 'Program/Course',
  CATEGORY_PLURAL: 'Programs/Courses',
  PROGRAMS_SINGULAR: 'Program',
  PROGRAMS_PLURAL: 'Programs',
  COURSES: 'Courses',
  VIDEO_SINGULAR: 'Video',
  VIDEO_PLURAL: 'Videos',
  EVENT_SINGULAR: 'Event',
  EVENT_PLURAL: 'Events',
  PACKAGE_SINGULAR: 'Package',
  COURSES_SINGULAR: 'Course',
  COURSES_PLURAL: 'Courses',
  POPULAR_CONTENT_SINGULAR: 'Popular Content',
  POPULAR_CONTENT_PLURAL: 'Popular Contents',
  TAOSONG_SINGULAR: 'Tao Calligraphy Meditation',
  TAOSONG_PLURAL: 'Tao Calligraphy Meditations',
  MEDITATION_SINGULAR: 'Meditation',
  MEDITATION_PLURAL: 'Meditations',
  LIVE_EVENT_SINGULAR: 'Live Event',
  BLESSING_SINGULAR: 'Blessing',
  BLESSING_PLURAL: 'Blessings',
  SQUARE_BANNER: 'Square Banner',
  HORIZONTAL_BANNER: 'Horizontal Banner',
  CONTENT: 'Content',
  CONTENT_TYPE: 'Content Type',
  ARTICLE_CATEGORY: 'Article Category',
  ARTICLE_CONTENT: 'Article Content',
  ARTICLE_CONTENT_PLURAL: 'Article Contents',
  ARTICLE: 'Article',
  ARTICLE_TYPE: 'Article Type',
};

export const ACTIONS = {
  CREATE: {
    value: 'create',
    label: 'Create New',
  },
  EDIT: {
    value: 'update',
    label: 'Edit',
  },
  TRANSLATE: {
    value: 'translate',
    label: 'Translate',
  },
  VIEW: {
    value: 'view',
    label: 'View',
  },
  DELETE: {
    value: 'delete',
    label: 'Delete',
  },
  CHANGE_PASSWORD: {
    value: 'change_password',
    label: 'Change Password',
  },
  ASSIGN_CATEGORIES: {
    value: 'assign_books',
    label: `Assign ${CONTENT_ALIAS.CATEGORY_PLURAL}`,
  },
  UPLOAD_ASSETS: {
    value: 'upload_assets',
    label: 'Upload Assets',
  },
  MANAGE_SUBSCRIPTIONS: {
    value: 'manage_subscriptions',
    label: 'Manage Subscriptions',
  },
  GRACE_SUBSCRIPTION: {
    value: 'grace_subscriptions',
    label: 'Grace Subscriptions',
  },
  VIEW_VIDEO_COMMENTS: {
    value: 'view_comments',
    label: 'View Comments',
  },
  UPLOAD_POSTER: {
    value: 'upload_poster',
    label: 'Upload Poster',
  },
  UPLOAD_VIDEO: {
    value: 'upload_video',
    label: 'Upload Video',
  },
  APPROVAL: {
    value: 'view and approve',
    label: 'View And Approve',
  },
  MANAGE_MEMBERS: {
    value: 'manage_members',
    label: 'Manage Members',
  },
  DOWNLOAD: {
    value: 'download',
    label: 'Download',
  },
  VIEW_TRANSACTION: {
    value: 'view transaction',
    label: 'View Transaction',
  },
  VIEW_REGISTRATIONS: {
    value: 'view Registrations',
    label: 'View Registrations',
  },
  LOGIN_AS_SUBSCRIBER: {
    value: 'login_as_subscriber',
    label: 'Login As Subscriber',
  },
  MANAGE_RECURRING_EVENTS: {
    value: 'manage_recurring_events',
    label: 'Manage Recurring Events',
  },
  MANAGE_NOTES: {
    value: 'manage_notes',
    label: 'Manage Notes',
  },
};

export const CATEGORY_TYPE = [
  {
    id: 'COURSE',
    label: 'Course',
  },
  {
    id: 'PROGRAM',
    label: 'Program',
  },
];

export const PAID_TYPE = {
  0: 'Free',
  1: 'Paid',
};

export const FEATURED_TYPE = {
  0: 'Not Featured',
  1: 'Featured',
};

export const IS_TRIAL = {
  0: 'No',
  1: 'Yes',
};

export const ALLOW_COMMENT_TYPE = {
  0: 'No',
  1: 'Yes',
};

export const RECURRING_TYPE = {
  0: 'No',
  1: 'Yes',
};

export const WEEK_NAME = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};

export const WEEK = {
  0: 'First Occurrence',
  1: 'Second Occurrence',
  2: 'Third Occurrence',
  3: 'Fourth Occurrence',
  4: 'Fifth Occurrence',
};

export const ARTICLE_TYPE = [
  {
    value: 'TAO_SCIENCE',
    label: 'Tao Science',
  },
  {
    value: 'TAO_RESEARCH',
    label: 'Tao Reaserch',
  },
];

export const ADMIN_SUBSCRIBER_ID = 1;

// Place it in CSS file
export const FOCUS_PALETTE_BGCOLOR = 'rgba(255, 255, 255, 0.25)';
