import React from 'react';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
} from '@mui/material';
import PropTypes from 'prop-types';
import CellValue from './cell-value.component';

const ViewNotesList = ({ columns, row }) => (
  <TableContainer component={Paper}>
    <Table sx={{ marginY: 2 }}>
      <TableBody>
        {columns.map((c) => {
          let render = true;
          if (c.conditional) {
            render = c.conditional(row);
          }

          if (render) {
            return (
              <TableRow key={`col-${c.id}`}>
                <TableCell
                  width="25%"
                  variant="head"
                  sx={{ border: 0, verticalAlign: 'top', paddingY: 1 }}
                >
                  {c.label}
                </TableCell>
                <TableCell
                  width="75%"
                  variant="body"
                  sx={{ border: 0, verticalAlign: 'top', paddingY: 1 }}
                >
                  <CellValue type={c.type} value={row[c.dataKey]} />
                </TableCell>
              </TableRow>
            );
          }

          return null;
        })}
      </TableBody>
    </Table>
  </TableContainer>
);

ViewNotesList.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  row: PropTypes.shape().isRequired,
};

export default ViewNotesList;
