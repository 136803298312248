import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  TextField,
  Grid,
  Divider,
  Box,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import validationRules, {
  maxLengthValidation,
} from '../../utils/validations.util';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  getSubscriberNotesList,
  createSubscriberNotes,
} from '../../services/subscribers.service';
import { VIEW_NOTES_COLUMNS } from '../../config/module-configs/subscribers.config';
import ViewNotesList from '../table-elements/view-notes-list.component';
import { getCurrentTimestamp } from '../../utils/datetime.util';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ManageNotes = ({ title, dataId, onCancel }) => {
  const {
    control,
    formState: { errors },
    getValues,
    reset,
    handleSubmit,
  } = useForm({
    defaultValues: {
      subject: '',
      notesBy: '',
      notes: '',
    },
  });

  const columns = VIEW_NOTES_COLUMNS;
  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [notesList, setNotesList] = useState([]);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const saveNewData = (payload) => {
    createSubscriberNotes(dataId, payload)
      .then((res) => {
        if (res.data.success) {
          setNotesList([
            {
              subject: getValues('subject'),
              notes: getValues('notes'),
              notesBy: getValues('notesBy'),
              createdAt: getCurrentTimestamp(),
            },
            ...notesList,
          ]);
          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'success',
            message: 'New note created successfully.',
          });
          reset();
          setFormSubmitted(false);
        }
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      subject: data.subject,
      notesBy: data.notesBy,
      notes: data.notes,
    };
    saveNewData(payload);
  };

  useEffect(() => {
    getSubscriberNotesList(dataId)
      .then((res) => {
        setNotesList(res.data);
        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      onClose={() => onCancel()}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle
        id="view-dialog-title"
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent
        dividers
        id="add-dialog-description"
        sx={{ overflow: 'visible' }}
      >
        {loading && <Loading />}

        {!loading && (
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="subject"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...maxLengthValidation(80),
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Subject"
                      type="text"
                      error={!!errors.subject}
                      helperText={errors?.subject?.message || null}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="notesBy"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...maxLengthValidation(100),
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Notes By"
                      type="text"
                      error={!!errors.notesBy}
                      helperText={errors?.notesBy?.message || null}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="notes"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Notes"
                      type="text"
                      multiline
                      minRows={3}
                      error={!!errors.notes}
                      helperText={errors?.notes?.message || null}
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'left', marginLeft: 2 }}>
        <Button
          type="submit"
          form="add-edit-form"
          color="primary"
          variant="contained"
          disabled={loading || formSubmitted}
        >
          Save
        </Button>
      </DialogActions>

      <DialogContent dividers>
        <Box fontWeight="bold">Notes List</Box>

        {!loading && notesList.length === 0 && (
          <Box display="flex" justifyContent="center" paddingY={8}>
            No notes found
          </Box>
        )}

        {!loading &&
          notesList.length > 0 &&
          notesList.map((item, index) => (
            <>
              <ViewNotesList columns={columns} row={item} />
              {index !== notesList.length - 1 && <Divider />}
            </>
          ))}
      </DialogContent>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

ManageNotes.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ManageNotes;
